import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

import '../styles/services.scss'

import { responsiveTitle1 } from '../components/typography.module.scss'

const ProcessPage = props => {
   /* const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const imageGalleryNodes =
    data && data.galleryImage && mapEdgesToNodes(data.galleryImage) */
  return (
    <Layout>
      <SEO title='Services' />
      <section id='process'>
        <Container>
          <div id='desktopProcess'>
            <div className='title'>
              <div className='col d-flex justify-content-center'>
                <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" space="preserve"><path fill="#fff" d="M79.521,65.605c-1.08-0.689-2.619-0.351-3.311,0.729C70.613,75.092,61.087,80.32,50.729,80.32  c-1.759,0-3.535-0.155-5.28-0.463c-0.002,0-0.004-0.002-0.007-0.002C32.18,77.52,22.229,66.711,20.626,53.734l7.267,7.098  c0.916,0.893,2.49,0.874,3.39-0.041c0.922-0.946,0.903-2.467-0.041-3.391l-10.88-10.626c-0.616-0.6-1.439-0.942-2.261-0.942  c-0.06,0-0.119,0-0.156,0.003c-0.892,0.03-1.748,0.427-2.347,1.091L5.616,57.975c-0.884,0.979-0.808,2.498,0.172,3.385  c0.442,0.397,1.013,0.617,1.606,0.617c0.676,0,1.324-0.287,1.779-0.789l6.641-7.352c1.664,15.227,13.247,27.973,28.743,30.73  c0.009,0.002,0.016,0.006,0.024,0.007c2.028,0.356,4.097,0.538,6.146,0.538c12.004,0,23.041-6.053,29.524-16.194  C80.962,67.803,80.634,66.318,79.521,65.605z" /><path fill="#fff" d="M94.049,37.546c-1.025-0.774-2.582-0.554-3.355,0.467l-5.752,7.608c-1.877-14.986-13.367-27.463-28.68-30.188  c-0.009-0.001-0.016-0.005-0.023-0.006c-2.028-0.358-4.098-0.54-6.147-0.54c-12,0-23.036,6.054-29.521,16.195  c-0.346,0.54-0.46,1.182-0.322,1.808c0.138,0.624,0.511,1.158,1.048,1.502c0.385,0.248,0.831,0.378,1.291,0.378  c0.823,0,1.578-0.413,2.02-1.104c5.601-8.757,15.129-13.984,25.489-13.984c1.757,0,3.531,0.154,5.274,0.461  c0.003,0,0.006,0.002,0.009,0.002c13.403,2.362,23.428,13.379,24.862,26.541l-8.162-6.688c-0.989-0.807-2.567-0.646-3.372,0.337  c-0.838,1.021-0.688,2.534,0.333,3.371l11.765,9.636c0.59,0.483,1.336,0.75,2.102,0.75c0.13,0,0.259-0.007,0.385-0.021  c0.889-0.104,1.707-0.574,2.248-1.291l8.979-11.874C95.313,39.85,95.104,38.343,94.049,37.546z" /></svg>
              </div>
              <h3 style={{ color: '#FCF7F7', marginTop: '24px', textAlign: 'center' }}>Our Process</h3>
              <p style={{ color: '#FCF7F7', fontSize:'12px', textAlign: 'center' }}>Hover over each step for more info</p>
            </div>
            <div className='row'>
              <div className='col d-flex justify-content-center'>
                <div className='stepGroup'>
                  <div>
                    <div className='stepNumber'>1</div>
                    <div className='stepTitle'>Identify Improvement Needed</div>
                    <div className='stepText'>Discuss needs and goals for the practice.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex' style={{ flexFlow: 'row-reverse' }}>
                <div className='stepGroup' style={{ right: '20%' }}>
                  <div>
                    <div className='stepNumber'>5</div>
                    <div className='stepTitle'>Evaluate Results</div>
                    <div className='stepText'>Check in with original goals. Implement changes as needed to ongoing office procedures. Refine improvement process.</div>
                  </div>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='stepGroup' style={{ left: '20%' }}>
                  <div>
                    <div className='stepNumber'>2</div>
                    <div className='stepTitle'>Plan of Action Proposal</div>
                    <div className='stepText'>We provide proposal outlining SightLine’s impact, including timelines and expectations.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row' style={{ marginTop: '40px' }}>
              <div className='col d-flex' style={{ flexFlow: 'row-reverse' }}>
                <div className='stepGroup' style={{ right: '5%' }}>
                  <div>
                    <div className='stepNumber'>4</div>
                    <div className='stepTitle'>Gather & Analyze Data</div>
                    <div className='stepText'>Interview staff, review records or interal data as needed.</div>
                  </div>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='stepGroup' style={{ left: '5%' }}>
                  <div>
                    <div className='stepNumber'>3</div>
                    <div className='stepTitle'>Implement Action Plan</div>
                    <div className='stepText'>Staff meeting with Doctor present. Implement training or improvement plan with metrics. Measure results and report.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id='mobileProcess'>
            <div>
              <div className='col d-flex justify-content-center'>
                <svg style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" space="preserve"><path fill="#fff" d="M79.521,65.605c-1.08-0.689-2.619-0.351-3.311,0.729C70.613,75.092,61.087,80.32,50.729,80.32  c-1.759,0-3.535-0.155-5.28-0.463c-0.002,0-0.004-0.002-0.007-0.002C32.18,77.52,22.229,66.711,20.626,53.734l7.267,7.098  c0.916,0.893,2.49,0.874,3.39-0.041c0.922-0.946,0.903-2.467-0.041-3.391l-10.88-10.626c-0.616-0.6-1.439-0.942-2.261-0.942  c-0.06,0-0.119,0-0.156,0.003c-0.892,0.03-1.748,0.427-2.347,1.091L5.616,57.975c-0.884,0.979-0.808,2.498,0.172,3.385  c0.442,0.397,1.013,0.617,1.606,0.617c0.676,0,1.324-0.287,1.779-0.789l6.641-7.352c1.664,15.227,13.247,27.973,28.743,30.73  c0.009,0.002,0.016,0.006,0.024,0.007c2.028,0.356,4.097,0.538,6.146,0.538c12.004,0,23.041-6.053,29.524-16.194  C80.962,67.803,80.634,66.318,79.521,65.605z" /><path fill="#fff" d="M94.049,37.546c-1.025-0.774-2.582-0.554-3.355,0.467l-5.752,7.608c-1.877-14.986-13.367-27.463-28.68-30.188  c-0.009-0.001-0.016-0.005-0.023-0.006c-2.028-0.358-4.098-0.54-6.147-0.54c-12,0-23.036,6.054-29.521,16.195  c-0.346,0.54-0.46,1.182-0.322,1.808c0.138,0.624,0.511,1.158,1.048,1.502c0.385,0.248,0.831,0.378,1.291,0.378  c0.823,0,1.578-0.413,2.02-1.104c5.601-8.757,15.129-13.984,25.489-13.984c1.757,0,3.531,0.154,5.274,0.461  c0.003,0,0.006,0.002,0.009,0.002c13.403,2.362,23.428,13.379,24.862,26.541l-8.162-6.688c-0.989-0.807-2.567-0.646-3.372,0.337  c-0.838,1.021-0.688,2.534,0.333,3.371l11.765,9.636c0.59,0.483,1.336,0.75,2.102,0.75c0.13,0,0.259-0.007,0.385-0.021  c0.889-0.104,1.707-0.574,2.248-1.291l8.979-11.874C95.313,39.85,95.104,38.343,94.049,37.546z" /></svg>
              </div>
              <h2 style={{ color: '#FCF7F7', paddingBottom: '24px' }}>Our Process</h2>
            </div>
            <div className='row'>
              <div className='col-lg d-flex justify-content-center'>
                <div className='stepGroup'>
                  <div>
                    <div className='stepNumber'>1</div>
                    <div className='stepTitle'>Identify Improvement Needed</div>
                    <div className='stepText'>Discuss needs and goals for the practice.</div>
                  </div>
                </div>
              </div>
              <div className='col-lg d-flex justify-content-center'>
                <div className='stepGroup'>
                  <div>
                    <div className='stepNumber'>2</div>
                    <div className='stepTitle'>Plan of Action Proposal</div>
                    <div className='stepText'>We provide proposal outlining SightLine’s impact, including timelines and expectations.</div>
                  </div>
                </div>
              </div>
              <div className='col-lg d-flex justify-content-center'>
                <div className='stepGroup'>
                  <div>
                    <div className='stepNumber'>3</div>
                    <div className='stepTitle'>Implement Action Plan</div>
                    <div className='stepText'>Staff meeting with Doctor present. Implement training or improvement plan with metrics. Measure results and report.</div>
                  </div>
                </div>
              </div>
              <div className='col-lg d-flex justify-content-center'>
                <div className='stepGroup'>
                  <div>
                    <div className='stepNumber'>4</div>
                    <div className='stepTitle'>Evaluate Results</div>
                    <div className='stepText'>Check in with original goals. Implement changes as needed to ongoing office procedures. Refine improvement process.</div>
                  </div>
                </div>
              </div>
              <div className='col-lg d-flex justify-content-center'>
                <div className='stepGroup'>
                  <div>
                    <div className='stepNumber'>5</div>
                    <div className='stepTitle'>Gather & Analyze Data</div>
                    <div className='stepText'>Interview staff, review records or interal data as needed.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p style={{ textAlign: 'center', color: '#FCF7F7', marginTop: '48px' }}>Based on the Action Research Model by Kurt Lewin</p>
        </Container>
        <div className='noise'></div>
      </section>
    </Layout>
  )
}

export default ProcessPage
